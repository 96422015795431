import React, { useEffect } from 'react';
import Head from 'next/head';
import marvelEmitter from '@marvelapp/react-ab-test/lib/emitter';
import PropTypes from 'prop-types';
import qs from 'qs';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import BuilderBlock from '/imports/homepageV2/ui/components/BuilderBlock';
import Flex from '/imports/core/ui/atoms/Flex';
import Footer from '/imports/homepageV2/ui/components/Footer';
import {
  getLocaleFromPath,
  getRoute,
  isBlogDomain,
  getDefaultLanguage,
  cdnFile,
  isJobTrackEnable,
  removeExperiment,
  cookieParser,
  expToogleLocaleToTest,
} from '/lib/helpers';
import Header from '/imports/homepageV2/ui/components/Header';
import HomepageContentResumedone from '/imports/homepageV2/ui/components/HomepageContentResumedone';
import { homepageMeta } from '/imports/homepage/api/constants';
import LandingPageCollapse from '/imports/homepageV2/ui/components/LandingPageCollapse';
import LandingPageContent from '/imports/homepageV2/ui/components/LandingPageContent';
import { LANGUAGES_CODES } from '/imports/generator/api/constants';
import { Push } from '/components/Link.js';
import SectionHeading from '/imports/homepageV2/ui/components/SectionHeading';
import SectionList from '/imports/homepageV2/ui/components/SectionList';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import useTracking from '/imports/core/hooks/useTracking';

const Homepage = ({ ctaClick, userIdentified, page }) => {
  const router = useRouter();
  const { breakpoint, host, isMobile } = useResponsive();
  const { locale = 'en' } = useIntl();
  const { trackEvent, sessionStartHandler, setPeople } = useTracking();
  const isHeader = isJobTrackEnable() && !isMobile;
  const backHackTrigged = localStorage.getItem('back_hack_triggered');
  const renderHack = () => {
    if (!backHackTrigged) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        if (isBlogDomain(host)) {
          window.location.href = '/builder/onboard/start';
        } else {
          window.location.href = `/${locale}/onboard/start`;
        }
        localStorage.setItem('back_hack_triggered', 'true');
        trackEvent('back_hack_triggered');
        window.history.go(0);
      };
    }
  };

  useEffect(() => {
    const { asPath } = router;

    sessionStartHandler();
    removeExperiment('landing_view_bg_experiment');
    removeExperiment('landing_view_experiment');
    removeExperiment('landing_view_scroll_experiment');
    removeExperiment('landing_view_right_section_experiment');
    removeExperiment('landing_view_budapest_experiment');
    setPeople();
    let obj = {};
    if (marvelEmitter.getActiveVariant('exp_landing_upload_resume')) {
      obj = {
        ...obj,
        exp_landing_upload_resume: marvelEmitter.getActiveVariant('exp_landing_upload_resume'),
      };
    }
    if (marvelEmitter.getActiveVariant('exp_toogle_language') && expToogleLocaleToTest.includes(locale)) {
      obj.exp_toogle_language = marvelEmitter.getActiveVariant('exp_toogle_language');
    }
    if (marvelEmitter.getActiveVariant('exp_h1_title_landing')) {
      obj.exp_h1_title_landing = marvelEmitter.getActiveVariant('exp_h1_title_landing');
    }

    if (typeof window !== 'undefined' && window?.ttq) {
      window.ttq.track('Page View');
    }
    trackEvent('homepage_view', obj);
    let destination = '/resumes';
    const { token } = cookieParser();
    renderHack();
    if (userIdentified || token) {
      let queryString = {
        from: asPath,
      };
      const locale = getLocaleFromPath(
        asPath.split('?')[0],
        getDefaultLanguage(host) === LANGUAGES_CODES.FRENCH,
        getDefaultLanguage(host),
      );
      if (isJobTrackEnable()) {
        destination = isHeader && !isMobile ? '/resumes' : '/app/dashboard';
        queryString = {
          template: isHeader && !isMobile ? 'resumes' : 'dashboard',
        };
      }
      const route = getRoute(`${destination}`, locale, false, host, isBlogDomain(host));
      const queryValue = `?${qs.stringify(queryString)}`;
      const redirectPage = `${route}${queryValue}`;
      Push(redirectPage, locale, destination);
      return;
    }
    try {
      document.getElementsByTagName('body')[0].classList.add('_container');
    } catch (error) {
      console.error(`Can't find body tag`);
    }
  }, []);

  const getMetaMySmartCV = () => {
    const localeTitle = homepageMeta['mysmartcv']?.[locale]?.title;
    const localeDescription = homepageMeta['mysmartcv']?.[locale]?.description;
    const title = homepageMeta['mysmartcv']?.[page]?.title || localeTitle;
    const description = homepageMeta['mysmartcv']?.[page]?.description || localeDescription;

    return (
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Head>
    );
  };

  const getMetaInfo = () => {
    switch (host) {
      case 'mysmartcv':
        return getMetaMySmartCV();
      case 'modeles-cv':
      case 'cvdeboss':
        return (
          homepageMeta['cvdeboss']?.[page]?.title &&
          homepageMeta['cvdeboss']?.[page]?.description && (
            <>
              <title>{homepageMeta['cvdeboss'][page].title}</title>
              <meta name="description" content={homepageMeta['cvdeboss'][page].description} />
            </>
          )
        );
      default:
        return;
    }
  };

  const handleCvImageClick = () => {
    trackEvent('homepage_cv_hero_image_click');
    Push('/onboarding/', locale, '/onboard/start');
  };

  const onClickLandingCTA = () => ctaClick('Create my CV');
  return (
    <>
      <Head>{getMetaInfo()}</Head>
      <StyledBodyCss>
        <HeroWrapper $direction="column" $fullWidth>
          <Header breakpoint={breakpoint} userIdentified={userIdentified} onClickLandingCTA={onClickLandingCTA} />
          <SectionHeading page={page} onClickLandingCTA={onClickLandingCTA} />
          <ClickableOverlayTransparent onClick={handleCvImageClick} />
        </HeroWrapper>
        <SectionList />
        <HomepageContentResumedone onClickLandingCTA={onClickLandingCTA} />
        <LandingPageContent />
        <LandingPageCollapse onClickLandingCTA={onClickLandingCTA} userIdentified={userIdentified} />
        <BuilderBlock onClickLandingCTA={onClickLandingCTA} />
        <Footer />
      </StyledBodyCss>
    </>
  );
};

const ClickableOverlayTransparent = styled.div`
  position: absolute;
  right: -120px;
  background-color: transparent;
  height: 70%;
  cursor: pointer;
  z-index: 55;
  width: 55%;
  transform: skew(-54deg);
  top: 76px;
`;

const HeroWrapper = styled(Flex)`
  padding-bottom: 100px;
  position: relative;
  background-position: center;
  background-image: url(${cdnFile('bg-hero-Buda3D.png')});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  &:after {
    content: '';
    object-fit: contain;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 69%, var(--light-values-white) 100%);
    position: absolute;
    inset: 0;
  }
  ${({ theme }) => theme.max('md')`
    background-position: center;
    padding-bottom: 108px;
    &:after {
      background-image: none;
    }
  `}
  ${({ isNewMobileDesign }) =>
    isNewMobileDesign &&
    css`
      background-image: none;
      padding-bottom: 0 !important;
    `}
`;

const StyledBodyCss = styled.div`
  ${({ theme: { isRTL } }) =>
    isRTL &&
    css`
      direction: rtl;
    `}
`;

export default Homepage;

Homepage.propTypes = {
  ctaClick: PropTypes.func,
  userIdentified: PropTypes.bool,
  page: PropTypes.string,
};
